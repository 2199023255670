<template>
    <div class="user">
        <div class="box">
            <div class="menu flex jc_s">
                <a href="/user" class="flex jc_s"
                    ><i class="iconfont iconmaijia_wode"></i>买家</a
                >
                <a href="/userBusiness" class="flex jc_s"
                    ><i class="iconfont iconyonghu1"></i>商家</a
                >
                <a href="/userExtension" class="flex jc_s"
                    ><i class="iconfont icontuiguang-1"></i>推广</a
                >
                <a href="/userCustomer" class="flex jc_s now"
                    ><i class="iconfont iconkehu"></i>客户</a
                >
            </div>
            <div class="p16">
                <div class="pb16 flex jc_b ai_c">
                    <div class="flex jc_s">
                        <i class="iconfont iconzongji f16 lan"></i>
                        <span class="col666 f14 ml10">共{{ total }}个用户</span>

                        <span
                            class="col666 f14"
                            :class="[dataType == 1 ? 'active-type' : '']"
                            style="margin-left: 30px"
                            @click="switchCustom(1)"
                            >预付客户</span
                        >
                        <span
                            class="col666 f14 ml14"
                            :class="[dataType == 2 ? 'active-type' : '']"
                            @click="switchCustom(2)"
                            >普通客户</span
                        >
                    </div>
                    <div class="flex jc_end f14 col333 ai_c">
                        <el-input
                            v-model="valSerch"
                            placeholder="请输入用户名"
                            style="width: 19.875rem"
                            class="xzinput xzinput2"
                        ></el-input>
                        <el-button
                            type="primary"
                            class="ssbtn"
                            style="width: 5.375rem; margin: 0 20px !important"
                            @click="filter"
                            >搜 索</el-button
                        >
                        <el-button
                            class="kqbtn"
                            style="
                                width: 112px;
                                height: 32px !important;
                                line-height: 32px !important;
                            "
                            @click="createSecret"
                            >生成密钥</el-button
                        >
                    </div>
                </div>
                <!--menub end-->
                <div class="table">
                    <el-table :data="tableData" border style="width: 88%" >
                        <el-table-column
                            label="序号"
                            type="index"
                            width='50'
                            fixed
                        >
                            <template slot-scope="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="nickname"
                            label="昵称"
                            min-width='70'
                            fixed
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.nickname }}</div>
                            </template>
                        </el-table-column>
                            <el-table-column
                            prop="username"
                            label="用户名"
                            min-width='80'
                            fixed
                        >
                            
                        </el-table-column>
                        <el-table-column prop="secret" label="密钥" width="270">
                            <template slot-scope="scope">
                                <div>{{ scope.row.secret }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="id_class"
                            label="身份"
                            min-width='40'

                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.id_class == 0">买家</div>
                                <div v-else-if="scope.row.id_class == 1">
                                    卖家
                                </div>
                                <div v-else="scope.row.id_class == 2">推手</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="channel_id"
                            label="渠道"
                            min-width="60"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.channel_id }}</div>
                            </template>
                        </el-table-column>
                        <!--<el-table-column prop="valtable6" label="订单类型" width="110">
						<template slot-scope="scope">            
							<div>{{scope.row.valtable6}}</div>
						 </template>    
					</el-table-column>-->

                        <el-table-column
                            prop="channel_type"
                            label="支付类型"
                            min-width="60"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.channel_type == 1">
                                    预付单
                                </div>
                                <div v-else="scope.row.channel_type == 2">
                                    普通单
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="channel_balance"
                            label="订单余额"
                            min-width="70"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.channel_balance }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="tel"
                            label="联系方式"
                            width="100"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.tel }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="created_at"
                            label="创建时间"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.created_at }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" min-width="120" fixed="right">
                            <template slot-scope="scope"
                                ><div class="flex jc_s ai_c">
                                    <el-button
                                        class="djbtn mr10"
                                        style="
                                            background: #2db7f5 !important;
                                            margin-right: 10px;
                                        "
                                        v-clipboard:copy="
                                            '渠道：' +
                                            scope.row.channel_id +
                                            '，' +
                                            '密钥：' +
                                            scope.row.secret
                                        "
                                        v-clipboard:success="onCopySuccess"
                                        v-clipboard:error="onCopyError"
                                        >复制</el-button
                                    >
                                    <div v-if="scope.row.channel_type == 1">
                                        <el-button
                                            class="djbtn mr10"
                                            @click="
                                                ckcz(scope.$index, scope.row)
                                            "
                                            >充值</el-button
                                        >
                                    </div>
                                    <div v-else="scope.row.channel_type == 2">
                                        <!--<el-button class="djbtn mr10" style="background: #DFDFDF!important;" disabled >充值</el-button>-->
                                        <el-button
                                            class="kqbtn mr10"
                                            @click="
                                                gxMessage(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            >更新秘钥</el-button
                                        >
                                    </div>
                                    <el-button
                                        class="delbtn"
                                        style="margin-left: 10px"
                                        @click="
                                            delMessage(scope.$index, scope.row)
                                        "
                                        >删除</el-button
                                    >
                                </div></template
                            >
                        </el-table-column>
                    </el-table>
                </div>
                <!--table end-->
                <div class="page pt16">
                    <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--box end-->
        <!---->
        <el-dialog
            id="czhi"
            class="dialogHeaderno"
            width="575px"
            :visible.sync="czDialog"
        >
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">充值</span>
                    <i
                        class="iconfont iconguanbi colfff f18"
                        @click="czDialog = false"
                    ></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form :model="czForm" ref="czForm">
                    <div class="qxmidm pb40">
                        <span class="qxmidmtxt"><b>*</b>充值金额</span>
                        <el-form-item prop="channel_balance">
                            <el-input
                                placeholder="请输入充值金额"
                                v-model="czForm.channel_balance"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                    </div>

                    <div class="qxmidm pb32">
                        <el-button
                            type="primary"
                            class="ssbtn"
                            style="width: 5.375rem; margin-right: 5rem"
                            @click="submitCzForm('czForm')"
                            >确认</el-button
                        >
                        <el-button
                            class="cancelbtn"
                            style="width: 5.375rem"
                            @click="resetCzForm('czForm')"
                            >取消</el-button
                        >
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>
        <el-dialog
            id="scmiyao"
            class="dialogHeaderno"
            width="575px"
            :visible.sync="scmyDialog"
        >
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">生成密钥</span>
                    <i
                        class="iconfont iconguanbi colfff f18"
                        @click="scmyDialog = false"
                    ></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form :model="scmyForm" :rules="scmyRules" ref="scmyForm">
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt"><b>*</b>用户名</span>
                        <!--<el-input placeholder="请输入用户名" v-model="scmyForm.id"   auto-complete="off"></el-input>-->
                        <!-- <el-autocomplete
                            value-key="nickname"
                            v-model="state1"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入用户名"
                            @select="handleSelect"
                        ></el-autocomplete> -->
                        <el-select
                            clearable
                            v-model="state1"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入用户名"
                            :remote-method="remoteMethod"
                            :loading="loading"
                            @focus="focusFun"
                        >
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.nickname"
                                :value="item.id"
                            >
                            </el-option>
                            <div v-if="page.isShow">
                                <el-pagination
                                    :current-page="currentPage"
                                    layout="prev, pager, next"
                                    :page-count="page.total"
                                    @current-change="changePage"
                                >
                                </el-pagination>
                            </div>
                        </el-select>
                    </div>

                    <!--<div class="qxmidm pb30">
				<span class="qxmidmtxt"><b>*</b>订单类型</span>
				<el-form-item>
					<el-select v-model="valSelectddlx" class="xzselect" style="width: 100%;">
						<el-option v-for="item in valSelectOptionsddlx" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</div>-->
                    <div class="qxmidm mb30">
                        <span class="qxmidmtxt"><b>*</b>支付类型</span>
                        <el-form-item prop="channel_type">
                            <el-select
                                v-model="scmyForm.channel_type"
                                class="xzselect"
                                style="width: 100%"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in channel_type_options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pt20 pb40">
                        <el-button
                            type="primary"
                            class="ssbtn"
                            style="width: 120px; margin-right: 50px"
                            @click="submitScmyForm('scmyForm')"
                            >确认</el-button
                        >
                        <el-button
                            class="cancelbtn"
                            style="width: 86px"
                            @click="resetScmyForm('scmyForm')"
                            >取消</el-button
                        >
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            valSerch: "",
            tableData: [],
            advanceData: [],
            commonData: [],
            dataType: 1,
            pageSize: 15,
            currentPage: 1,
            total: 0,
            czDialog: false,
            czForm: {
                channel_id: "",
                channel_type: 1,
                channel_balance: "",
            },
            scmyDialog: false,
            scmyForm: {
                id: "",
                channel_type: "",
            },
            scmyRules: {
                id: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                channel_type: [
                    {
                        required: true,
                        message: "请选择支付类型",
                        trigger: "blur",
                    },
                ],
            },
            channel_type_options: [
                {
                    value: "1",
                    label: "预付单",
                },
                {
                    value: "2",
                    label: "普通单",
                },
            ],

            valSelectddlx: "",
            valSelectOptionsddlx: [
                {
                    value: "1",
                    label: "源码",
                },
                {
                    value: "2",
                    label: "接口",
                },
            ],
            restaurants: [],
            state1: "",
            condition: "",
            // 生成密钥搜索
            options: [],
            loading: false,
            page: {
                isShow: true,
                name: "",
                total: 0,
                limit: 8,
                offset: 0,
                currentPage: 1,
            },
        };
    }, //data
    mounted() {
        this.getData();
    }, //
    methods: {
        switchCustom(type) {
            this.dataType = type;
            if (this.dataType == 1) {
                this.tableData = this.advanceData;
            } else {
                this.tableData = this.commonData;
            }
        },
        createSecret() {
            this.state1 = "";
            this.scmyForm.channel_type = "";
            this.scmyDialog = true;
        },
        // 生成密钥用户名搜索
        SearchData(name, e = 1) {
            let offset = (e - 1) * this.page.limit;
            this.loading = true;
            let data = {
                nickname: name,
                limit: this.page.limit + "",
                offset: offset + "",
            };
            this.$api
                .getSearchData(data)
                .then((res) => {
                    this.options = res.data.data.rows;
                    this.page.total = Math.ceil(
                        res.data.data.count / this.page.limit
                    );
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
        remoteMethod(e) {
            if (e != this.page.name) {
                console.log("远程    ------");
                this.page.currentPage = 1;
                this.page.isShow = false;
                this.$nextTick(() => {
                    this.page.isShow = true;
                });
            }
            this.page.name = e;
            this.SearchData(this.page.name);
        },
        changePage(e) {
            this.SearchData(this.page.name, e);
        },
        focusFun() {
            this.options = [];
        },
        handleSelect(item) {
            const that = this;
            that.scmyForm.id = item.id;
        }, //
        getData() {
            const that = this;
            let data = {
                limit: that.pageSize,
                offset: (that.currentPage - 1) * that.pageSize,
                s: "",
            };
            if (that.condition) {
                data.s = that.condition;
            }
            this.$api.getCustomer(data).then((res) => {
                that.total = res.data.data.count;
                let list = res.data.data.row;
                // that.tableData = res.data.data.row;
                that.advanceData = []
                that.commonData = []
                list.forEach((item) => {
                    if (item.channel_type == 1) {
                        that.advanceData.push(item);
                    } else {
                        that.commonData.push(item);
                    }
                });
                if (this.dataType == 1) {
                    that.tableData = that.advanceData;
                } else {
                    that.tableData = that.commonData;
                }
                // console.log(this.advanceData)
            });
        }, //getData
        filter() {
            this.currentPage = 1;
            this.offset = 0;
            this.condition = this.valSerch;
            this.getData();
        }, //
        fomatterxh(row, index) {
            //return +'hh'
        }, //
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        ckcz(index, row) {
            // this.czDialogczDialog = true;
            this.czDialog = true;
            this.czForm.channel_id = row.channel_id;
        }, //
        submitCzForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this;
                    let data = {
                        channel_id: that.czForm.channel_id,
                        // channel_type: 1,
                        type: 1,
                        channel_balance: that.czForm.channel_balance,
                    };
                    console.log(data);
                    this.$api.updateCustomer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                            setTimeout(function () {
                                that.czDialog = false;
                                that.getData();
                            }, 100);
                        }
                    });
                }
            });
        },
        resetCzForm(formName) {
            this.$refs[formName].resetFields();
            this.czDialog = false;
        }, //resetScmyForm
        submitScmyForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this;
                    // console.log('state1:',this.state1)
                    let data = {
                        id: this.state1,
                        channel_type: that.scmyForm.channel_type,
                    };
                    this.$api.setSecret(data).then((res) => {
                        console.log("res", res);
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                            setTimeout(function () {
                                that.scmyDialog = false;
                                that.getData();
                            }, 100);
                        }
                    });
                }
            });
        }, //submitScmyForm
        resetScmyForm(formName) {
            this.$refs[formName].resetFields();
            this.scmyDialog = false;
        }, //resetScmyForm

        gxMessage(index, row) {
            const that = this;
            // console.log('row.channel_id',row.channel_id)
            this.$confirm("确定要更新秘钥吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let data = {
                        channel_id: row.channel_id,
                        type: 2,
                    };
                    this.$api.updateCustomer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消更新",
                    });
                });
        }, //cshMessage
        delMessage(index, row) {
            //console.log('row.channel_type',row.channel_type)
            const that = this;
            this.$confirm("确定删除吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let data = { channel_id: row.channel_id };

                    this.$api.deleteCustomer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        }, //delMessage
        onCopySuccess(e) {
            this.$message.success("复制成功");
        },
        onCopyError(e) {
            this.$message.error("复制失败");
        },
    }, //methods
};
</script>

<style lang="less" scoped>
.active-type {
    color: #4295fb !important;
    transform: scale(1.2);
    display: inline-block;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        transform: scale(0.5);
        background-color: #4295fb;
    }
}
</style>